<template>
  <!-- 新增科目弹窗 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close  title="新增科目" v-model="dialogVisible" width="25%" append-to-body>
    <el-form :model="addData" label-width="100px" label-position="right" size="small">
      <el-form-item label="上级科目：">
        <el-select v-model="addData.subjectId" filterable placeholder="请选择" size="small" @change="changeAddsubject">
          <el-option
            v-for="item in this.list"
            :disabled="!!item.fzhsName"
            :key="item.id"
            :label="item.name3"
            :value="item.id"
          >
            <span style="float: left">{{ item.name3 }}</span>
            <span style="float: right;color: red;font-size: 14px;">{{item.fzhsName}}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="科目名称：">
        <el-input v-model="addData.name" style="width:80%;" size="small"></el-input>
      </el-form-item>
      <el-form-item class="item" label="余额方向：">
        <el-radio v-model="addData.type" :label="1" :disabled="disabled">借</el-radio>
        <el-radio v-model="addData.type" :label="2" :disabled="disabled">贷</el-radio>
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel()" size="small">取 消</el-button>
        <el-button type="primary" @click="addSubjectSure" size="small">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { saveSubject } from "@/api/subject"
export default {
  setup() {
    
  },
  props: {
    list:{
      type:Array,
      default:[]
    },
    subjectMatchName1:{
      type:String,
      default:''
    },
    comId:{
      type:Number,
      default:0
    },
  },
  data () {
    return {
      dialogVisible:false,
      addData: {
        subjectId: undefined,
        name: '',
        type: 0,
        comId:this.comId
      }
    }
  },
  created(){
    this.addData.name = this.subjectMatchName1
  },
  methods: {
    changeAddsubject() {
      this.list.map(v => {
        if (v.id == this.addData.subjectId) {
          this.addData.type = v.type;
        }
      });
    },
    cancel(){
      this.dialogVisible = false
    },
    addSubjectSure(){
      saveSubject(this.addData).then(res =>{
        if(res.data.msg == "success"){
          this.dialogVisible = false
          this.$qzfMessage('新增成功')
          this.$emit('success',this.addData.comId)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.el-select{
  margin:0
}
</style>