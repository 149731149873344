<template>
  <div>
    <div class="top_btns">
      <div class="left_box">
      </div>
      <div class="right_box">
        <el-button size="small" type="primary" @click="declaration()" :disabled="!$buttonStatus('ztqy_plcjlssj')" :loading="cjLoading">
          <el-icon><Aim /></el-icon><span  > 批量采集</span>
        </el-button>
        <el-button size="small" type="primary" @click="batchMatch()" :disabled="!$buttonStatus('ztqy_plpp')" icon="Connection">
           批量匹配
        </el-button>
      </div>
    </div>
    <div>
      <el-table stripe :data="list" :height="contentStyleObj" border @select="handleSelectionChange" v-loading="loading" @sort-change="sortChange" ref="tableScrollLs" id="tableLazyLs" @select-all="handleSelectionChangeAll">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" min-width="55" />
        <el-table-column  label="编号" align="center" prop="sort" width="80" sortable="custom" fixed="left" >
          <template #default="scope">
            <TableSortCell :row="scope.row" idKey="comId" />
          </template>
        </el-table-column>
        <el-table-column align="left" prop="name" fixed label="公司名称" min-width="250" class="gsmc" show-overflow-tooltip>
          <template #default="scope">
            <TagNameCopy :row="scope.row"  idKey="comId" :showAuthIcon="false" :showUnderLine="true" :status="scope.row?.status"></TagNameCopy>
          </template>
        </el-table-column>
        <el-table-column prop="districtName" label="税局" width="65" align="center">
          <template #default="scope">
            <span>{{ $cityFilter(scope.row.district) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="accountSystem" label="会计制度" min-width="240" align="left">
          <template #default="scope">
            {{$accountSystemFilter(scope.row.accountSystem)}}
          </template>
        </el-table-column>
        <el-table-column align="left" prop="districtName" label="建账状态" min-width="120">
          <template #default="scope">
            <div class="item_icon">
              <p v-if="scope.row.setUpStatusJz == '6'"><i class="iconfont icon-gantanhao"></i>{{statusJz(scope.row.setUpStatusJz)}}</p>
              <p v-if="scope.row.setUpStatusJz == '2'"><i class="iconfont icon-info"></i>{{statusJz(scope.row.setUpStatusJz)}}</p>
              <p v-if="scope.row.setUpStatusJz == '1'"><i class="iconfont icon-duihao"></i>{{statusJz(scope.row.setUpStatusJz)}}</p>
            </div>
          </template>
        </el-table-column>
       
        <el-table-column align="left" prop="districtName" label="采集" min-width="170">
          <template #default="scope">
            <div class="div_p">
              <div class="item_icon">
                <p>
                  <i :class="$setUpStatusJzCjIcon(scope.row.taskStatus,scope.row.bussinessStatus)"></i>
                  <el-tooltip v-if="scope.row.bussinessLog || scope.row.errLog" class="scope.row" effect="dark" placement="top-start">
                    <template #content>
                      <div v-html="scope.row.bussinessLog?scope.row.bussinessLog:'' + scope.row.errLog?scope.row.errLog:''">
                      </div>
                    </template>
                    <span>{{$setUpStatusJzCj(scope.row.taskStatus, scope.row.bussinessStatus)}}<i class="iconfont icon-wenhao" style="color:red;margin-left: 4px;" v-if="scope.row.bussinessLog || scope.row.errLog">
                   </i></span>
                  </el-tooltip>
                  <span v-else>
                    <span>{{$setUpStatusJzCj(scope.row.taskStatus, scope.row.bussinessStatus)}}</span>
                  </span>
                </p>
              </div>
              <el-button @click="declareDetail(scope.row)" size="small" type="text">采集记录</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="districtName" label="导入" min-width="170">
          <template #default="scope">
            <div class="div_p">
              <div class="item_icon">
                <p v-if="scope.row.importTaskStatus == '0'"><i class="iconfont icon-gantanhao"></i>{{$setUpStatusImport(scope.row.importTaskStatus)}}
                </p>
                <p v-if="scope.row.importTaskStatus == '1'"><i class="iconfont icon-info"></i>{{$setUpStatusImport(scope.row.importTaskStatus)}}
                </p>
                <p v-if="scope.row.importTaskStatus == '2'"><i class="iconfont icon-info"></i>{{$setUpStatusImport(scope.row.importTaskStatus)}}</p>
                <p v-if="scope.row.importTaskStatus == '3'"><i class="iconfont icon-duihao"></i>
                  <el-tooltip class="item" effect="dark" placement="top-start" :disabled="!scope.row.importErrLog">
                    <template #content>
                      <div v-html="scope.row.importErrLog">
                      </div>
                    </template>
                    <span>{{$setUpStatusImport(scope.row.importTaskStatus)}} <i class="iconfont icon-wenhao" style="color:red;margin-left: 4px;" v-if="scope.row.importErrLog">
                    </i> </span>
                  </el-tooltip>
                </p>
                <p v-if="scope.row.importTaskStatus == '4'"><i class="iconfont icon-cuowu"></i>
                  <el-tooltip class="item" effect="dark"  placement="top-start" :disabled="!scope.row.importErrLog">
                    <template #content>
                      <div v-html="scope.row.importErrLog">
                      </div>
                    </template>
                    <span>{{$setUpStatusImport(scope.row.importTaskStatus)}}<i class="iconfont icon-wenhao" style="color:red;margin-left: 4px;" v-if="scope.row.importErrLog">
                   </i></span>
                  </el-tooltip>
                </p>
                <p v-if="scope.row.importTaskStatus == '5'"><i class="iconfont icon-cuowu"></i>{{$setUpStatusImport(scope.row.importTaskStatus)}}</p>
              </div>
              <el-button @click="declareDetailsImport(scope.row)" size="small" type="text">导入记录</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="left" prop="districtName" label="匹配" min-width="170">
          <template #default="scope">
            <div class="div_p">
              <div class="item_icon">
                <p v-if="scope.row.matchTaskStatus == '0'"><i class="iconfont icon-gantanhao"></i>{{$setUpStatusMatch(scope.row.matchTaskStatus)}}
                </p>
                <p v-if="scope.row.matchTaskStatus == '1'"><i class="iconfont icon-info"></i>{{$setUpStatusMatch(scope.row.matchTaskStatus)}}
                </p>
                <p v-if="scope.row.matchTaskStatus == '2'"><i class="iconfont icon-info"></i>{{$setUpStatusMatch(scope.row.matchTaskStatus)}}</p>
                <p v-if="scope.row.matchTaskStatus == '3'"><i class="iconfont icon-duihao"></i>
                  <el-tooltip class="item" effect="dark" placement="top-start" :disabled="!scope.row.matchErrLog">
                    <template #content>
                      <div v-html="scope.row.matchErrLog">
                      </div>
                    </template>
                    <span>{{$setUpStatusMatch(scope.row.matchTaskStatus)}}<i class="iconfont icon-wenhao" style="color:red;margin-left: 4px;" v-if="scope.row.matchErrLog">
                   </i></span>
                  </el-tooltip>
                </p>
                <p v-if="scope.row.matchTaskStatus == '4'"><i class="iconfont icon-cuowu"></i>
                  <el-tooltip class="item" effect="dark" placement="top-start" :disabled="!scope.row.matchErrLog">
                    <template #content>
                      <div v-html="scope.row.matchErrLog">
                      </div>
                    </template>
                    <span>{{$setUpStatusMatch(scope.row.matchTaskStatus)}}<i class="iconfont icon-wenhao" style="color:red;margin-left: 4px;" v-if="scope.row.matchErrLog">
                    </i></span>
                  </el-tooltip>
                </p>
                <p v-if="scope.row.matchTaskStatus == '5'"><i class="iconfont icon-cuowu"></i>{{$setUpStatusMatch(scope.row.matchTaskStatus)}}</p>
              </div>
              <el-button @click="declareDetailsMatch(scope.row)" size="small" type="text">匹配记录</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="manager" label="操作" min-width="180">
          <template #default="scope">
            <div class="div_p">
              <el-button @click="importHisVoucher(scope.row)" size="small" type="text" :disabled="!$buttonStatus('ztqy_drpz') || scope.row.comCode != 0" icon="Folder">导入凭证</el-button>
              <el-button size="small" type="text" @click="matchSubjects(scope.row)" :disabled="!$buttonStatus('ztqy_plpp') || scope.row.comCode != 0" icon="Connection">匹配</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="transfer"/>
    </div>
    <!-- <div>
      <el-button size="small" type="danger" @click="batchRevocation">批量撤销</el-button>
    </div> -->
  </div>
  <!-- 批量采集弹窗 -->
  <el-dialog :close-on-click-modal="false"
    v-model="dialogVisible"
    title="批量采集"
    width="30%"
    destroy-on-close>
    <el-form :rules="rules" ref="ruleForm" :model="form">
      <el-form-item label="软件名称" :label-width="formLabelWidth">
        <el-select v-model="form.name" placeholder="请选择软件" size="small" filterable>
          <el-option label="金财互联" value="金财互联"></el-option>
          <el-option label="云帐房" value="云帐房"></el-option>
          <el-option label="亿企代账" value="亿企代账"></el-option>
          <el-option label="柠檬云" value="柠檬云"></el-option>
          <el-option label="柠檬云专业版" value="柠檬云专业版"></el-option>

          <el-option label="柠檬云免费版" value="柠檬云免费版"></el-option>
          <el-option label="大账房" value="大账房"></el-option>

          <el-option label="账无忧1.0" value="账无忧1.0"></el-option>


          <el-option label="浪潮云" value="浪潮云"></el-option>
          <el-option label="木牛盒子" value="木牛盒子"></el-option>
          <el-option label="精斗云" value="精斗云"></el-option>
          <el-option label="唯易" value="唯易"></el-option>
          <el-option label="芸豆会计" value="芸豆会计"></el-option>
          <el-option label="易代账" value="易代账"></el-option>
          <el-option label="诺诺云" value="诺诺云"></el-option>
          <el-option label="账无忧" value="账无忧"></el-option>
          <el-option label="云代账" value="云代账"></el-option>
          <el-option label="云代账V4" value="云代账V4"></el-option>

          <el-option label="财务云" value="财务云"></el-option>
          <el-option label="好会计" value="好会计"></el-option>
          <el-option label="天蓬云账" value="天蓬云账"></el-option>
          <el-option label="金账师" value="金账师"></el-option>
          <el-option label="快账" value="快账"></el-option>
          <el-option label="云算盘" value="云算盘"></el-option>
          <el-option label="呱呱云" value="呱呱云"></el-option>
          <el-option label="慧算账" value="慧算账"></el-option>
          <el-option label="慧代账" value="慧代账"></el-option>
          <el-option label="捷锐云" value="捷锐云"></el-option>
          <el-option label="365云财税" value="365云财税"></el-option>
          <el-option label="众马云" value="众马云"></el-option>
          <el-option label="智慧财税" value="智慧财税"></el-option>












          <!-- <el-option label="快合财税" value="快合财税"></el-option>
          <el-option label="代账魔方" value="代账魔方"></el-option> -->



        </el-select>
      </el-form-item>

      <el-form-item prop="userName" label="用户名" :label-width="formLabelWidth">
        <el-input style="width:200px" v-model="form.userName" size="small"></el-input>
      </el-form-item>

      <el-form-item prop="password" label="密码" :label-width="formLabelWidth">
        <el-input style="width:200px" v-model="form.password" size="small"></el-input>
      </el-form-item>

      <el-form-item v-if="form.name == '云代账'|| form.name == '云代账V4'|| form.name == '代账魔方' || form.name == '财务云'" prop="orgCode" label="公司代码" :label-width="formLabelWidth">
        <el-input style="width:200px" v-model="form.orgCode" size="small"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" size="small">确定</el-button>
      </span>
    </template>
  </el-dialog>

  <qzf-import-back :hideButton="true" @success="getList()" @success2="importHisVoucher2" ref="historyVoucher" from="历史凭证" :comId="importComId"></qzf-import-back>
  <qzf-import-back :hideButton="true" @success="getList()" @success2="importHisSubject2" ref="historySubject" from="历史科目" :comId="importComId"></qzf-import-back>

  <otherHisSubject ref="otherHisSubject" @success="importHisSubject3"></otherHisSubject>
  <otherHisVoucher ref="otherHisVoucher" @success="importHisVoucher3"></otherHisVoucher>
  
  <declareDetails ref="declareDetails"></declareDetails>
  <declareDetails ref="declareDetailsImport"></declareDetails>
  <declareDetails ref="declareDetailsMatch"></declareDetails>
  <matchSubject ref="matchSubject" @success="getList" @success2="matchSubjectss" :oldList ="this.oldList" :affiliationPeriod="this.affiliationPeriod"></matchSubject>
  <batchMatchSubject :oldList ="this.oldList" ref="batchMatchSubject" :listHis="this.listHis"></batchMatchSubject>

</template>

<script>
import { companyHisImportList } from "@/api/company"
import { importVoucherHis , importHisSubjectFall ,oldMatchSubject , robotMatchSubject } from "@/api/old"
import {  findSubjects } from "@/api/subject"
import declareDetails from "../../batch/components/declareDetails.vue";
import otherHisSubject from "@/components/import/son/otherHisSubject"
import otherHisVoucher from "@/components/import/son/otherHisVoucher"
import matchSubject from "./matchSubject.vue"
import batchMatchSubject from "./batchMatchSubject"
import { sendTask , quitTask} from "@/api/collection"
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
export default {
  name: "transfer",
  props: {
    activeName:String,
    listQuery: {}
  },
  components:{
    declareDetails,
    otherHisSubject,
    otherHisVoucher,
    matchSubject,
    batchMatchSubject,
    TagNameCopy,
    TableSortCell
  },
  data(){
    return {
      formLabelWidth: '120px',
      total:0,
      list:[],
      form: {
        name: '云帐房',
        userName:'',
        password:'',
        orgCode:''
      },
      rules: {
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        orgCode: [
          { required: true, message: '公司代码', trigger: 'blur' },
        ],
      },
      gsMainIds: [],
      pageStatus: true,
      dialogVisible: false,
      checkOptions: [
        {
          value: 0,
          label: '未采集',
        },
        {
          value: 1,
          label: '排队中',
        },
        {
          value: 2,
          label: '采集中',
        },
        {
          value: 3,
          label: '采集完成',
        },
        {
          value: 4,
          label: '采集失败',
        },
      ],
      importOptions: [
        {
          value: 0,
          label: '未导入',
        },
        {
          value: 1,
          label: '排队中',
        },
        {
          value: 2,
          label: '导入中',
        },
        {
          value: 3,
          label: '导入完成',
        },
        {
          value: 4,
          label: '导入失败',
        },
      ],
      matchOptions: [
        {
          value: 0,
          label: '未匹配',
        },
        {
          value: 1,
          label: '排队中',
        },
        {
          value: 2,
          label: '匹配中',
        },
        {
          value: 3,
          label: '匹配完成',
        },
        {
          value: 4,
          label: '匹配失败',
        },
      ],
      importComId:0,
      beginTime:'',
      endTime:'',
      importPeriod:'',
      loading:false,
      listLow:[],
      multipleSelection:[],
      oldList:[],
      listHis:[],
      listAll2:[],
      affiliationPeriod:'',
      cjLoading:false,
      totalPage:0,
      pageSize:50,
      tableData:[],
      currentPage:1,
      allChecked:false
    }
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  created(){
   // this.getList()
    this.initBus()
    this.contentStyleObj= this.$getHeight(246)
  },
  methods:{
    initBus(){
      this.$bus.on("refreshTask", () => {
        if(!this.pageStatus){
          return
        }
        if(this.gsMainIds.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
        //刷新业务
        let status = false
        this.list.forEach(res => {
          if ([1,2,99].includes(res.importTaskStatus) || res.taskStatus == 2) {
            status = true;
          }
        })
        if(status == true) {
          companyHisImportList(this.listQuery).then(res=>{
            this.tableData = res.data.data.list ? res.data.data.list :[]
            this.total = res.data.data.total
            this.setScroll()
          })
        }
      });
    },
    getList(){
      this.loading = true
      companyHisImportList(this.listQuery).then(res=>{
        this.loading = false
        this.tableData = res.data.data.list ? res.data.data.list :[]
        this.total = res.data.data.total
        this.setScroll()
      })
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.multipleSelection = []
      this.gsMainIds = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLs");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.multipleSelection.length == that.tableData.length){
              that.$refs.tableScrollLs.toggleAllSelection()
              that.allChecked = true
            }else if(that.multipleSelection.length != 0){
              that.multipleSelection.map(v=>{
                that.list.map(item=>{
                  if(item.comId == v.comId){
                    that.$nextTick(()=>{
                     that.$refs.tableScrollLs.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    getIds(){
      this.gsMainIds = []
      this.multipleSelection.map(info=>{
        this.gsMainIds.push(info.comId)
      })
    },
    handleSelectionChange(val) {
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.comId == v.comId){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.multipleSelection = newArr
      }else{
        this.multipleSelection = val;
      }
      this.getIds()
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.multipleSelection = this.tableData
        this.getIds()
        this.allChecked = true
      }else{
        this.multipleSelection = []
        this.allChecked = false
        this.gsMainIds = []
      }
    },
    // 采集详情
    declareDetail(e) {
      this.$refs.declareDetails.init(e.comId,e.startAccountPeriod,e.taskName)
    },
    // 导入详情
    declareDetailsImport(e){
      this.$refs.declareDetailsImport.init(e.comId,e.startAccountPeriod,e.importTaskName)
    },
    // 匹配详情
    declareDetailsMatch(e){
      this.$refs.declareDetailsMatch.init(e.comId,e.startAccountPeriod,e.matchTaskName)
    },
    //批量采集
    declaration() {
      if(this.gsMainIds.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let data = localStorage.getItem("lssj")
      if(data){
        this.form = JSON.parse(data)
      }
      this.dialogVisible = true
    },
    //批量采集确定
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.cjLoading = true
          let cond = {
              // comId: v * 1,
              orgCode: this.form.orgCode,
              userName: this.form.userName,
              password: this.form.password,
              softwareName: this.form.name
            }
          //临时保存账号密码
          localStorage.setItem("lssj",JSON.stringify(this.form))
          this.dialogVisible = false
          sendTask({comIds:this.gsMainIds,taskName: "his-cj-historyCollect",cond}).then(res => {
            this.cjLoading = false
            if(res.data.msg == 'success') {
              this.$qzfMessage('任务已发起',3)
              this.getList()
              this.$queueMessage(res.data.data.queue)

            }
          })
        }
      });
    },
    importHisVoucher(row){
      this.importComId = row.comId
      this.$refs.historyVoucher.handImport()
    },
    importHisSubject(row){
      this.importComId = row.comId
      this.$refs.historySubject.handImport()
    },
    importHisSubject2(e){
      this.importPeriod = e.period
      this.$refs.otherHisSubject.dialogFormVisible = true
      this.$refs.otherHisSubject.init(e.item)
    },
    importHisVoucher2(e){
      this.beginTime = e.beginTime
      this.endTime = e.endTime
      this.$refs.otherHisVoucher.dialogFormVisible = true
      this.$refs.otherHisVoucher.init(e.item)
    },
    importHisSubject3(e){
      this.loading = true
      let param = {
        comId:this.importComId,
        period:this.importPeriod,
        item:e.arr
      }
      importHisSubjectFall(param).then(res=>{
        this.loading = false
        if(res.data.msg == "success"){
          this.$qzfMessage('导入成功')
          this.getList()
        }
      })
    },
    importHisVoucher3(e){
      this.loading = true
      let param = {
        beginTime:this.beginTime,
        endTime:this.endTime,
        comId:this.importComId,
        item:e.arr
      }
      importVoucherHis(param).then(res=>{
        this.loading = false
        if(res.data.msg == "success"){
          this.$qzfMessage('导入成功')
          this.getList()
        }
      })
    },
    // 匹配
    async matchSubjects(row){
      if(row.importTaskStatus != 3){
        this.$qzfMessage('请先导入凭证',2)
        return
      }
      await oldMatchSubject({comId:row.comId}).then(res=>{
        this.oldList = res.data.data.list
        this.affiliationPeriod = res.data.data.periods.join("-")
      })
      await findSubjects({comId:row.comId}).then(res=>{
        this.listLow = res.data.data.listLow
        this.listAll = res.data.data.list
        
        if(!this.listLow){
          this.$qzfMessage('请先进行建账',2)
          return
        }
        this.$refs.matchSubject.init(row.comId,this.listLow,this.listAll)
      })
    },
    matchSubjectss(row){
      findSubjects({comId:row}).then(res=>{
        this.listLow = res.data.data.listLow
        this.listAll = res.data.data.list
        this.$refs.matchSubject.init(row,this.listLow,this.listAll)
      })
    },
    batchRevocation(){
      if(this.multipleSelection.length == 0){
        this.$qzfMessage('请至少选择一家公司',2)
        return
      }
      let status = false
      this.multipleSelection.map(v=> {
        if(v.taskStatus !=1){
          status = true
        }
      })
      if(status){
        this.$qzfMessage("只有排队中的才可以进行撤销", 2)
        return
      }
      let reqNos = []
      this.multipleSelection.map(v=>{
        reqNos.push(v.reqNo)
      })
      quitTask({reqNos}).then(res =>{
        if(res.data.msg == "success"){
          this.getList()
          this.$qzfMessage("撤销成功")
        }
      })
    },
    async batchMatch(){
      if(this.gsMainIds.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let temp = this.multipleSelection.find(v=> v.comCode != 0)
      if(temp){
        this.$qzfMessage("所选 ["+temp.name+"] 公司已有历史数据不可重复导入匹配", 1)
        return
      }
      await robotMatchSubject(this.gsMainIds).then(res=>{
        if(res.data.msg == "success"){
          this.oldList = res.data.data.list
          this.listHis = res.data.data.listHis
          this.listAll = res.data.data.listAll
          this.listAll2 = res.data.data.listAll2
          this.comIds = res.data.data.comIds
          this.$refs.batchMatchSubject.init(this.comIds,this.listAll,this.listAll2)
        }
      })
    },
    statusJz(e){
      if(e == 1){
        return '已建账'
      }else if(e ==2){
        return '无需建账'
      }else if(e == 6){
        return '未建账'
      }
    },
    // 表格排序
    sortChange(data) {
      this.listQuery.desc = data.order == "descending" ? 1 : 0;
      this.getList()
    },
  }
}
</script>

<style lang="less" scoped>
.top_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.filter-item1 {
  margin-right: 5px;
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    font-size: 13px;
    margin-right: 4px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }

  p{
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}
.sbcg {
  padding: 0 !important;
}
.sbyc {
  color: red !important;
  padding: 0 !important;
}
.el-select {
  width: 200px;
  margin-left:0px
}
</style>
<style lang="scss">
.el-table__column-filter-trigger i {
  color: #fff !important;
  width: 10px;
}
.none-border .cell {
  padding: 0;
}
.el-table th.el-table__cell>.cell.highlight {
    color: #fff !important;
}
</style>