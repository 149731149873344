<template>
  <el-select style="width:100%;border: 0;" filterable v-model="id" placeholder="请选择" size="small" clearable @change="changeValue">
    <el-option label="全部" value="all" @click="addSubject" v-if="!!this.addName">
      <span style="color:red">新增+</span>
    </el-option>
    <el-option
    v-for="(itemcode,index) in this.list" 
    :key="index" 
    :label="itemcode.name3" 
    :value="itemcode.id"></el-option>
  </el-select>
  <add-match-subject ref="addMatchSubject" :subjectMatchName1="this.subjectMatchName" :comId="this.comId" :subjectType="this.subjectType" :list="this.listAll" @success="updateSubjectList"></add-match-subject>
</template>
<script>
import addMatchSubject from './addMatchSubject.vue'

export default {
  components: { addMatchSubject },
  props:{
    subjectId: {
      type: Number,
      default: 0
    },
    comId:{
      type:Number,
      default:0
    },
    list:{
      type:Array,
      default:[]
    },
    listAll:{
      type:Array,
      default:[]
    },
    subjectMatchName:{
      type:String,
      default:''
    },
    addName:{
      type:Boolean,
      default:true
    },
  },
  created() {
    //console.log(this.list);
  },
  data(){
    return {
    }
  },
  computed: {
    id: {
      get() {
        if(!this.subjectId){
          return ""
        }
        return this.subjectId
      },
      set(val) {
        this.$emit("update:subjectId", val * 1)
        this.$emit("success", val * 1)
      }
    }
  },
  methods: {
    addSubject(){
      this.$refs.addMatchSubject.dialogVisible = true
    },
    updateSubjectList(row){
      //console.log(row);
      this.$emit('success2',row)
    },
  }
}
</script>