<template>
  <div>
    <!-- 导入其他科目余额表 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="科目配置" v-model="dialogFormVisible" width="80%">
			<el-form ref="dataForm" :model="temp" label-position="right" label-width="120px" :inline="true">
					<div class="common_dialog_box clearfix">
						<div class="common_title">1.设置数据形式</div>
							<div class="item_one clearfix">
									<el-form-item label="期初数据形式:" class="item">
									<el-radio-group v-model="dataType">
											<el-radio :label="1">借贷两列</el-radio>
											<el-radio :label="2">方向+余额</el-radio>
									</el-radio-group>
									</el-form-item>
							</div>
						</div>
					<div class="common_dialog_box clearfix">
					<div class="common_title">3.余额表数据调整</div>
					<div class="item_one clearfix">
						<h6 style="color:red"><span>提示：</span>*请按照表头列次，调整导入数据的顺序</h6>

						<div class="table-big">
							<div class="table">
								<div v-if="dataType == 1" style="border-bottom:1px solid #eee;background-color:#17a2b8;color:#fff;">
									<div class="tableHead">科目编码</div>
									<div class="tableHead">科目名称</div>
									<div class="tableHead">期初余额借方</div>
									<div class="tableHead">期初余额贷方</div>
									<div class="tableHead">本期发生额借方</div>
									<div class="tableHead">本期发生额贷方</div>
									<div class="tableHead">累计发生额借方</div>
									<div class="tableHead">累计发生额贷方</div>
									<div class="tableHead">期末余额借方</div>
									<div class="tableHead">期末余额借方</div>
								</div>
								<div v-if="dataType == 2" style="border-bottom:1px solid #eee;background-color:#17a2b8;color:#fff">
									<div class="tableHead">科目编码</div>
									<div class="tableHead">科目名称</div>
									<div class="tableHead">方向</div>
									<div class="tableHead">期初余额</div>
									<div class="tableHead">本期发生额借方</div>
									<div class="tableHead">本期发生额贷方</div>
									<div class="tableHead">累计发生额借方</div>
									<div class="tableHead">累计发生额贷方</div>
									<div class="tableHead">期末余额借方</div>
									<div class="tableHead">期末余额借方</div>
								</div>
                <draggable 
									v-model="list" 
									group="people" 
									@start="drag=true" 
									@end="drag=false" 
									item-key="id">
									<template #item="{element,index}">
										<div class="table-div">
											<div class="top_icon">
												<i @click="delItem(index)" class="iconfont icon-shanchu"></i>
											</div>
											<div v-for="(item2,index2) in element" :key="index2" class="table_icon">
												<span v-if="item2 != 'del'">
													{{item2}}
												</span>
												<span v-else>
													<i @click="delItemRaw(index2)" class="iconfont icon-shanchu"></i>
												</span>
											</div>
										</div>
									</template>
								</draggable>
							</div>
						</div>  
					</div>
				</div>
			</el-form>
			<template #footer>
				<div  class="dialog-footer">
					<el-button @click="dialogFormVisible = false" size="small">取消</el-button>
					<el-button type="primary" @click="updateData()" size="small">确定</el-button>
				</div>
			</template>
    </el-dialog>
  </div>

</template>
    

<script>

const delcommafy = function (num){
		if(!num) return num;
		num = num.toString();
		num = num.replace(/,/gi, '');
		return num;
};

import draggable from 'vuedraggable'
export default {
    props: {
    
    },
		components: {
      draggable,
    },
    data () {
			return {
				list: [
					[1001,1002,1003,1004,1005,1006,1007,1008],
					[2,2,3,4,5,67,33,8],
					[3,2,3,4,5,67,33,8],
					[4,2,3,4,5,67,33,8],
					[5,2,3,4,5,67,33,8],
					[6,2,3,4,5,67,33,8],
					[7,2,3,4,5,67,33,8],
					[8,2,3,4,5,67,33,8],
					[9,2,3,4,5,67,33,8],
				],
				dataType: 1,
				dataCodeType: 1,
				temp: {
					level: "8",
					len: ['4','2','2','2'],
					spe: "无",
					nameSpe: "无",
				},
				level:[
					{ value: '1',label: '1'},
					{ value: '2',label: '2'},
					{ value: '3',label: '3'},
					{ value: '4',label: '4'},
					{ value: '5',label: '5'},
					{ value: '6',label: '6'}
				], //科目层级
				code_type:[{ value: '无',label: '无'},{ value: '.', label: '.'},{ value: '-',label: '-'},{ value: '_',label: '_'}], //编码分隔符
				code_width:[{value: '1',label: '1'},{value: '2',label: '2'},{value: '3',label: '3'},{value: '4',label: '4'},{value: '5',label: '5'},{value: '6',label: '6'},{value: '7',label: '7'},{value: '8',label: '8'}], // 编码长度
				dialogFormVisible: false,
        exampleCode1:'1002',
				exampleCode2:'01',
				exampleCode3:'02',
				exampleCode4:'03',
				exampleCode5:'04',
				codeSeparator:'',
			};
    },
    created(){
    },
    methods: {
        init(data){
					let long = 0
					data.map(v=>{
						if(long < v.length) long = v.length
					})
					// console.log(data)
					let list = []
					data.map(v=>{
						for(let i=0;i<long;i++){
							if(!list[i]){
								list.push([])
							}
							if(v.length > i){
								list[i].push(v[i])
							} else {
								list[i].push("")
							}
						
						}
					}) 
					// console.log(list)
					let dd = this.$qzfCopy(list[0])
					for(let i=0;i<dd.length;i++){
						dd[i] = 'del'
					}
					list.push(dd)
					// console.log(list)
					this.list = list
        },
        delItem(index){
            // if(index == this.list.length-1){
            //     this.$message.error('本列不可删除');
            //     return
            // }
            this.list.splice(index,1);
        },
        delItemRaw(index){
					this.list.map(v=>{
						v.splice(index,1);
					})
        },
        changeLevel(){
					let lens = []
					for(let i=0;i<this.temp.level*1;i++){
						lens.push("")
					}
					this.temp.len = lens
        },
        updateData(){
					// console.log(this.list)
					if(this.list.length < 10){
							this.$message.error('列数异常请退出当前页面重新导入');
							return
					}
					let arr = [] 
					if(this.dataType == 1){
						for(let i=0;i<this.list[0].length;i++){
							if(this.list[0][i] == null){
								this.list[0][i] = ''
							}else{
                if(this.list[0][i].replace(/\./g, "").replace(/\-/g, "").replace(/\_/g, "")*1){
									let subjectName = this.list[1][i].trim()
									if(this.temp.nameSpe != "无"){
										subjectName = subjectName.split(this.temp.nameSpe)[subjectName.split(this.temp.nameSpe).length - 1]
									}
									arr.push({
										periodBeginIn: delcommafy(this.list[2][i])*1,
										periodBeginOut: delcommafy(this.list[3][i])*1,
										yearInt: delcommafy(this.list[6][i])*1,
										yearOut: delcommafy(this.list[7][i])*1,
										periodEndIn: delcommafy(this.list[8][i])*1,
										periodEndOut: delcommafy(this.list[9][i])*1,
										periodInt: delcommafy(this.list[4][i])*1,
										periodOut: delcommafy(this.list[5][i])*1,
										// subjectCode: v["科目代码"].replace(".",""),
										// subjectName: v["科目名称"],
										subjectCode: this.list[0][i].trim(),
										subjectName: subjectName,
									})
								}
							}
						}
					}else if(this.dataType == 2){
						for(let i=0;i<this.list[0].length;i++){
							if(this.list[0][i].replace(/\./g, "").replace(/\-/g, "").replace(/\_/g, "")*1){
								let periodBeginIn = 0
								let periodBeginOut = 0
								if(this.list[2][i].indexOf('借') > -1){
									periodBeginIn = delcommafy(this.list[3][i])*1
								}else{
									periodBeginOut = delcommafy(this.list[3][i])*1
								}
								let subjectName = this.list[1][i].trim()
								if(this.temp.nameSpe != "无"){
									subjectName = subjectName.split(this.temp.nameSpe)[subjectName.split(this.temp.nameSpe).length - 1]
								}
								arr.push({
										periodBeginIn: delcommafy(periodBeginIn)*1,
										periodBeginOut: delcommafy(periodBeginOut)*1,
										yearInt: delcommafy(this.list[6][i])*1,
										yearOut: delcommafy(this.list[7][i])*1,
										periodEndIn: delcommafy(this.list[8][i])*1,
										periodEndOut: delcommafy(this.list[9][i])*1,
										periodInt: delcommafy(this.list[4][i])*1,
										periodOut: delcommafy(this.list[5][i])*1,
										// subjectCode: v["科目代码"].replace(".",""),
										// subjectName: v["科目名称"],
										subjectCode: this.list[0][i].trim(),
										subjectName: subjectName,
								})
							}
						}
					}
					this.dialogFormVisible = false
					this.$emit("success",{arr:arr,temp:this.temp})
        },
    }
};
</script>
<style lang="scss" scoped>
.item_s {
    margin-bottom: 10px;
  }
  .table-big{
      width: 100%;
      height: 320px;
      overflow: auto;
      border: 1px solid #eee;
      margin-top: 10px;
    .table{
        min-width: 3000px;
        height: 400px;
        
        // white-space:nowrap;
        .table-div{
            vertical-align: top;
            display: inline-block;
            width: 120px;
            border-right: 1px solid #eee;
            div{
                height: 30px;
                line-height: 30px;
                width: 100%;
                // display: inline-block;
                display: flex;
                white-space: nowrap;
                overflow: hidden;
                font-size: 14px;
                color: #333;
                padding:0 5px;
            }
            .iconfont icon-shanchu{
                cursor: pointer;
            }
            &:last-child .top_icon i{
                display: none;
            }
            &:last-child{
                width: 26px !important;
            }
        }
        .top_icon{
            width: 100%;
            border-bottom: 1px solid #eee;
            i{
                font-size: 16px;
                color: #F56C6C;
                cursor: pointer;
                margin-top: 7px;
            }

        }
        .table_icon{
            border-bottom: 1px solid #eee;
            i{
                font-size: 16px;
                color: #F56C6C;
                cursor: pointer;
                margin-top: 7px;
            }
            
        }
    }
    
  }
.common_dialog_box .item{
	margin-left:0px !important;
	// width:48% !important
}
.common_dialog_box .common_title{
	font-weight: 600;
}
.tableHead{
	display:inline-block;
	width:120px;
	text-align:center;
	border-right:1px solid #eee;
	height:30px
}
</style>


