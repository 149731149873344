<template>
  <el-dialog :close-on-click-modal="false"
    v-model="dialogVisible"
    title="匹配科目"
    width="50%"
  >
      <el-switch v-model="nameSplit" active-text="是否自动识别名称分隔符"/>
      <el-table :data="oldList" style="width: 100%" border  max-height="250">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column label="原科目" min-width="150">
          <template #default="scope">
            <span>{{+ scope.row.oldCode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="示例" min-width="320">
          <template #default="scope">
            <span>{{ scope.row.oldName }}</span> 
          </template>
        </el-table-column>
        <el-table-column label="匹配科目" min-width="180">
          <template #default="scope">
            <matchSubjectNewCode :list="this.listAll" :listAll="this.listAll" :addName="true" :comId="this.comId" :subjectMatchName="scope.row.oldCode+scope.row.oldName" v-model:newCode="scope.row.newCode" ></matchSubjectNewCode>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-bottom:10px;margin-top: 10px;" v-if="this.listHis">
        历史数据的历史数据：
      </div>
      <el-table :data="listHis" style="width: 100%" border max-height="250" v-if="this.listHis">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column label="原科目" min-width="150">
          <template #default="scope">
            <span>{{+ scope.row.oldCode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="示例" min-width="320">
          <template #default="scope">
            <span>{{ scope.row.oldName }}</span> 
          </template>
        </el-table-column>
        <el-table-column label="匹配科目" min-width="180">
          <template #default="scope">
            <matchSubjectNewCode :list="this.listAll2" :listAll="this.listAll2" :addName="true" :comId="this.comId" :subjectMatchName="scope.row.oldCode+scope.row.oldName" v-model:newCode="scope.row.newCode" ></matchSubjectNewCode>
          </template>
        </el-table-column>
      </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="batchMatch" :loading="newLoading">确认</el-button>
      </span>
    </template>
    <!-- <span>当前已导入凭证账期{{affiliationPeriod}}</span> -->
  </el-dialog>

</template>

<script>
import { robotMatch } from "@/api/old"
import matchSubjectList from "./matchSubjectList.vue"
import matchSubjectNewCode from "./matchSubjectNewCode.vue"

export default {
  name:'batchMatchSubject',
  components:{ matchSubjectList ,matchSubjectNewCode },
  props:{
    affiliationPeriod:{
      type:String,
      default:''
    },
    oldList:{
      type:Array,
      default:[]
    },
    listHis:{
      type:Array,
      default:[]
    }
  },
  data(){
    return {
      dialogVisible:false,
      list:[],
      listAll:[],
      listAll2:[],
      innerList:[],
      comId:0,
      isLoading:false,
      newLoading:false,
      contentStyleObj:{}, //高度变化
      nameSplit:true,
    }
  },
  created(){
    // this.contentStyleObj=this.$getHeight(700)
  },
  methods:{
    init(comIds,listAll,listAll2){
      this.comId = comIds
      this.dialogVisible = true
      this.listAll = listAll
      this.listAll2 = listAll2
      //console.log(listAll2);
    },
    batchMatch(){
      let param = {
        comIds:this.comId,
        list:this.oldList,
        listHis:this.listHis,
        nameSplit:this.nameSplit
      }
      let status = false
      let statusHis = false
      if(param.list){
        param.list.map(v => {
        if(!v.newCode || !v.oldCode || !v.oldName){
            status = true
          }
        })
        if(status){
          this.$qzfMessage('请补充完整',2)
          return
        }
      }
      if(param.listHis){
        param.listHis.map(v => {
        if(!v.newCode || !v.oldCode || !v.oldName){
            statusHis = true
          }
        })
        if(statusHis){
          this.$qzfMessage('请补充完整',2)
          return
        }
      }
      this.newLoading = true
      
      robotMatch(param).then(res=>{
        this.newLoading = false
        if(res.data.msg == "success"){
          this.$qzfMessage('任务已发起')
          this.$emit('success')
          this.dialogVisible = false
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.table_height{
  overflow-y: overlay;
  overflow-x:hidden;
}
</style>